
.jumbotron {
    padding: 20px;
}

.central {
   display: flex;
   min-height: 75vh; 
   align-items: center;
   h1 {
     text-align: center; 
     flex: 1;
     color: #777;
     text-shadow: 0px -1px 0px #000000; // 0px -1px 0px #000000;
   }
}



// BGP Communities Labels


.label-bgp-community {
  margin-right: 5px;
  display: inline-block;
}

.label-bgp-unknown {
    color: #333;
    background-color: #ddd;
}




.card {
  position: relative;
  background: white;
  margin: 20px 0px 20px 0px;
  border-radius: 2px;
  box-shadow: 0px 1px 3px #cccccc;
  padding: 15px;
}

.card-header {
  text-transform: uppercase;
}

